import { reactive, Ref, ref } from 'vue';
import { $api, $loading } from '@/api/index';
import { toast } from '@/utils/toast';

export interface AccSumInfo {
    assignKey1: string;
    saleName: string;
    saDateNm: string;
    dplaceName: string;
    itnm: string;
    saleQunt: number;
    errChk: string;
    txtMsg: string;
}



export function accsums(){
    
    const isConnected = ref(true);
    const accsumlist: Ref<AccSumInfo[]> = ref([]);
    

      async function fetchDatas(frDate: string, toDate: string, officeCode: string, id: string) {
        await $loading(`/accsum?frDate=${frDate}&toDate=${toDate}&officeCode=${officeCode}&id=${id}`, 'POST').then(
          (res: any) => {
            if(res.data == ''){
                accsumlist.value = [];
              toast('데이터가 존재하지 않습니다.'); 
            }else{
              if(res.data[0].errChk == -1){
                toast(res.data[0].txtMsg); 
              }else{
                accsumlist.value = res.data;
              }
              
            }
            
          }
        );
      }

    return { isConnected, accsumlist, fetchDatas};
}